<template>
  <div class="card p-shadow-6">
    <h1>CRM - Potênciais Clientes</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Button
      label="Potêncial Cliente"
      icon="pi pi-plus"
      class="p-button-success p-mb-2"
      @click="addProspect"
    />

    <DataTable
      :value="prospectList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm p-datatable-responsive"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords}"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column field="name" header="Nome" sortable filterField="source">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="entity_company"
        header="Empresa"
        sortable
        filterField="entity_company"
      >
        <template #body="slotProps">
          {{ slotProps.data.entity_company }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="seller_name"
        header="Vendedor"
        sortable
        filterMatchMode="contains"
      >
        <template #body="slotProps">
          {{ slotProps.data.seller_name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="classification"
        header="Classificação"
        sortable
        filterField="classification"
        dataType="boolean"
      >
        <template #body="slotProps">
          {{ slotProps.data.classification }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="classificationList"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        field="source"
        header="Origem"
        sortable
        filterField="status"
        dataType="boolean"
      >
        <template #body="slotProps">
          {{ slotProps.data.source }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="sourceList"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        field="status"
        header="Estado"
        sortable
        filterField="status"
        dataType="boolean"
      >
        <template #body="slotProps">
          {{ slotProps.data.status }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="statusList"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        field="next_date"
        header="Próximo Contacto"
        sortable
        filterField="next_date"
        dataType="date"
      >
        <template #body="slotProps">
          <span class="p-column-title">Próximo Contacto:</span>
          {{ slotProps.data.next_date }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column :headerStyle="{ width: '100px' }">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Orçamentos'"
            icon="pi pi-euro"
            class="p-button-rounded p-button-primary p-button-outlined p-mr-2"
            @click="goBudget(slotProps.data.id)"
          />
          <Button
            v-tooltip.left="'Editar'"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="updateProspect(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="showProspectForm"
      :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
      :header="
        prospectModel.id == undefined
          ? 'Novo Potêncial Cliente'
          : 'Actualizar Potêncial Cliente'
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="expenditureForm"
        class="p-col-12"
        @submit.prevent="saveProspect"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid p-mt-2">
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <Dropdown
                name="seller"
                :options="sellerList"
                optionValue="id"
                optionLabel="name"
                v-model="prospectModel.seller_id"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('seller') },
                  'form-control',
                ]"
                :showClear="true"
                :filter="true"
              />
              <label for="seller">Vendedor</label>
            </span>
            <small v-if="errors.has('seller')" class="p-error" role="alert">
              Vendedor é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <span class="p-float-label">
              <InputText
                :name="'name'"
                type="text"
                v-model="prospectModel.name"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('name') },
                  'form-control',
                ]"
              />
              <label for="name">Nome</label>
            </span>
            <small v-if="errors.has('name')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <span class="p-float-label">
              <InputText
                :name="'entity_company'"
                type="text"
                v-model="prospectModel.entity_company"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('entity_company') },
                  'form-control',
                ]"
              />
              <label for="entity_company">Empresa</label>
            </span>
            <small
              v-if="errors.has('entity_company')"
              class="p-error"
              role="alert"
            >
              Empresa é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-5 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="classification"
                :options="classificationList"
                v-model="prospectModel.classification"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('classification') },
                  'form-control',
                ]"
                :showClear="true"
              />
              <label for="classification">Classificação</label>
            </span>
            <small
              v-if="errors.has('classification')"
              class="p-error"
              role="alert"
            >
              Classificação é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="source"
                :options="sourceList"
                v-model="prospectModel.source"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('source') },
                  'form-control',
                ]"
                :showClear="true"
              />
              <label for="source">Origem</label>
            </span>
            <small v-if="errors.has('source')" class="p-error" role="alert">
              Origem é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="manager"
                id="manager"
                :options="externalManagerList"
                :optionValue="'id'"
                :dataKey="'id'"
                :optionLabel="getExternalManagerLabel"
                :filter="true"
                v-model="prospectModel.manager"
                v-bind:class="[
                  { 'p-invalid': errors.has('manager') },
                  'form-control',
                ]"
                :showClear="true"
              />
              <label for="manager">Gestor Externo</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="status"
                :options="statusList"
                v-model="prospectModel.status"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('status') },
                  'form-control',
                ]"
                :showClear="true"
              />
              <label for="status">Estado</label>
            </span>
            <small v-if="errors.has('status')" class="p-error" role="alert">
              Estado é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <v-date-picker
              v-model="prospectModel.next_date"
              :min-date="new Date()"
              is-required
              :masks="{
                input: 'DD-MM-YYYY',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <span class="p-float-label">
                  <input
                    v-bind:class="[
                      { 'p-invalid': errors.has('next_date') },
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled',
                    ]"
                    :value="inputValue"
                    v-on="inputEvents"
                    name="next_date"
                    v-validate="'required'"
                  />
                  <label for="next_date"> Próximo Contacto </label>
                </span>
              </template>
            </v-date-picker>
            <small v-if="errors.has('next_date')" class="p-error" role="alert">
              Próximo Contacto é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Textarea
                :name="'comments'"
                type="text"
                :autoResize="true"
                rows="3"
                v-model="prospectModel.comments"
                v-bind:class="['form-control']"
              />
              <label for="comments">Comentário</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveProspect"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import prospectService from "../services/prospect.service";
import externalManagerService from "../services/externalManager.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate } from "../helpers/helpers";

export default {
  name: "Prospect",
  data() {
    return {
      loading: true,
      filters: {
        next_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        classification: { value: null, matchMode: FilterMatchMode.EQUALS },
        source: { value: null, matchMode: FilterMatchMode.EQUALS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        entity_company: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      prospectList: [],
      sourceList: [],
      classificationList: [],
      statusList: [],
      externalManagerList: [],
      sellerList: [],
      prospectModel: {},
      prospectModelBack: {},
      showProspectForm: false,
    };
  },
  computed: {},
  async created() {
    this.prospectList = await prospectService.getProspects();
    this.sourceList = await prospectService.getSources();
    this.classificationList = await prospectService.getClassification();
    this.statusList = await prospectService.getStatus();
    this.externalManagerList = await externalManagerService.getAll();
    this.sellerList = await prospectService.getSellers();
    this.loading = false;
  },
  methods: {
    goBudget(id) {
      let route = this.$router.resolve({
        path: `/crm/budget/prospect/${id}`,
      });
      window.open(route.href, "_blank");
    },
    getEmptyModel() {
      return {
        seller_id: null,
        entity_company: null,
        name: null,
        classification: null,
        source: null,
        status: null,
        next_date: null,
        comments: null,
        manager: null,
      };
    },
    getExternalManagerLabel(info) {
      if (info.type == "") {
        return info.name;
      }
      return `${info.name} | ${info.type}`;
    },
    addProspect() {
      this.prospectModel = this.getEmptyModel();
      this.showProspectForm = true;
    },
    closeForm() {
      this.$validator.pause();
      this.$validator.reset();
      this.showProspectForm = false;
      this.prospectModel = this.prospectModelBack;
    },
    saveProspect() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.prospectModel.id === undefined) {
          return this.saveNewProspect();
        }

        return this.saveUpdateProspect();
      });
    },
    saveNewProspect() {
      if (
        this.prospectModel.comments == null ||
        this.prospectModel.comments == ""
      ) {
        delete this.prospectModel.comments;
      }
      if (
        this.prospectModel.manager == null ||
        this.prospectModel.manager == ""
      ) {
        delete this.prospectModel.manager;
      }
      this.prospectModel.next_date = getOnlyDate(this.prospectModel.next_date);
      return prospectService
        .createProspect(this.prospectModel)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao Criar Potêncial Cliente",
              detail: "",
              life: 3000,
            });
          }
          this.prospectList.unshift(response);
          this.closeForm();
          return this.$toast.add({
            severity: "success",
            summary: "Potêncial Cliente criado com sucesso",
            detail: "",
            life: 3000,
          });
        });
    },
    updateProspect(data) {
      this.prospectModelBack = { ...data };
      this.prospectModel = data;
      this.showProspectForm = true;
    },
    saveUpdateProspect() {
      if (
        this.prospectModel.comments == null ||
        this.prospectModel.comments == ""
      ) {
        delete this.prospectModel.comments;
      }
      if (
        this.prospectModel.manager == null ||
        this.prospectModel.manager == ""
      ) {
        delete this.prospectModel.manager;
      }
      if (this.prospectModel.next_date instanceof Date) {
        this.prospectModel.next_date = getOnlyDate(
          this.prospectModel.next_date
        );
      }

      return prospectService
        .updateProspect(this.prospectModel.id, this.prospectModel)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao Criar Potêncial Cliente",
              detail: "",
              life: 3000,
            });
          }
          this.prospectModel = response;
          this.closeForm();
          return this.$toast.add({
            severity: "success",
            summary: "Potêncial Cliente criado com sucesso",
            detail: "",
            life: 3000,
          });
        });
    },
  },
};
</script>
<style scoped lang="scss">
.p-column-filter {
  width: 100%;
}
</style>
